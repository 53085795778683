import clsx from "clsx"

type Props = {
	label: string
	name: string
	placeholder: string
	class?: string
	type?: "text" | "email" | "tel"
	required?: boolean
	readOnly?: boolean
}

export const TextInput = ({
	type = "text",
	required = true,
	...props
}: Props) => {
	return (
		<label class={clsx(["flex flex-col gap-1", props.class])}>
			<p class="font-sansCnd uppercase tracking-[0.03125em]">{props.label}</p>
			<input
				type={type}
				name={props.name}
				placeholder={props.placeholder}
				class="w-full rounded-lg border border-blue bg-blue/0 px-4 py-3 text-blue placeholder:text-blue/75 focus-visible:outline-blue [&:user-invalid]:border-orange"
				required={required}
			/>
		</label>
	)
}
