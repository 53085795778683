import { actions } from "astro:actions"
import clsx from "clsx"
import { useRef, useState } from "preact/hooks"
import type { JSX } from "preact/jsx-runtime"
import { button } from "../styles/button"
import { TextInput } from "./TextInput"

const Confirmation = (props: { reset: () => void; class?: string }) => {
	return (
		<div
			class={clsx(
				props.class,
				"absolute flex flex-col items-center gap-10 transition-all duration-200 ease-out",
			)}
		>
			<p class="text-center text-lg">
				Thank you for reaching out! We have received your message and a member
				of our team will reach out to you soon.
			</p>

			<button
				onClick={props.reset}
				class={button({
					class: "min-w-[9.375rem] justify-self-center md:col-span-full",
					variant: "tertiary",
				})}
			>
				Submit Another
			</button>
		</div>
	)
}

export const ContactForm = () => {
	const [state, setState] = useState({ pending: false, submitted: false })
	const rForm = useRef<HTMLFormElement>(null)

	async function onSubmit(e: JSX.TargetedEvent<HTMLFormElement>) {
		e.preventDefault()

		const form = rForm.current
		if (!form) return

		setState({ pending: true, submitted: false })

		const data = new FormData(form)
		const result = await actions.contactForm(data)

		if (result.error) return alert("Something went wrong. Please try again.")

		setState({ pending: false, submitted: true })
	}

	function reset() {
		rForm.current?.reset()
		setState({ pending: false, submitted: false })
	}

	return (
		<div class="relative">
			<Confirmation
				reset={reset}
				class={clsx(
					state.submitted
						? "pointer-events-auto visible opacity-100"
						: "pointer-events-none invisible opacity-0",
				)}
			/>
			<form
				class={clsx(
					state.submitted && "pointer-events-none invisible opacity-0",
					"grid gap-x-6 gap-y-7 transition-all duration-200 ease-out md:grid-cols-2",
				)}
				onSubmit={onSubmit}
				ref={rForm}
			>
				<TextInput
					label="First Name"
					name="firstName"
					placeholder="First Name"
					readOnly={state.pending}
				/>
				<TextInput
					label="Last Name"
					name="lastName"
					placeholder="Last Name"
					readOnly={state.pending}
				/>
				<TextInput
					label="Company"
					name="company"
					placeholder="Company"
					class="md:col-span-full"
					readOnly={state.pending}
				/>
				<TextInput
					label="Email"
					name="email"
					placeholder="Email"
					type="email"
					readOnly={state.pending}
				/>
				<TextInput
					label="Phone"
					name="phone"
					placeholder="Phone"
					type="tel"
					readOnly={state.pending}
				/>

				<label class="flex flex-col gap-1 md:col-span-full">
					<p class="uppercase tracking-[0.03125em]">Message</p>
					<textarea
						name="message"
						class="min-h-32 w-full resize-y rounded-lg border border-blue bg-blue/0 px-4 py-3 text-blue placeholder:text-blue/75 focus-visible:outline-blue [&:user-invalid]:border-orange"
						required
						placeholder="Your message..."
						readOnly={state.pending}
					/>
				</label>

				<button
					class={button({
						class: "min-w-[9.375rem] justify-self-center md:col-span-full",
						variant: "primary",
					})}
					disabled={state.pending}
				>
					{state.pending ? "Submitting..." : "Submit"}
				</button>
			</form>
		</div>
	)
}
